// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins.scss";
@import "~bootstrap/scss/utilities.scss";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";


@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/images";

// Helpers
@import "~bootstrap/scss/helpers.scss";

@import "~bootstrap/scss/utilities/api";

@font-face {
  font-family: 'Open Sans';
  src: url('../webfonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../webfonts/OpenSans-Medium.ttf') format('truetype');
  font-weight: bold;
  font-display: swap;
}

html, body {
  font-family: "Open Sans", sans-serif;
}

body {
  overflow-x: hidden;
  min-height: 100vh;
}

#page {
  text-align: center;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  img.logo {
    max-width: 100%;
    width: 800px;
    height: auto;
  }

  a {
    max-width: 100%;
    overflow: hidden;
    word-break: break-all;
    color: #E49A40;
  }

}